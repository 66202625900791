<template>
  <div class="bg-gray-300 tag rounded pl-3 pr-2 h-9 inline-flex items-center">
    {{ label }}
    <span class="ml-2 cursor-pointer text-gray-500 hover:text-gray-700" @click="clear">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
      </svg>
    </span>
  </div>
</template>
</template>
<script>
export default {
  name: 'w-tag',
  props: {
    label: {
      type: String
    }
  },
  methods: {
    clear() {
      this.$emit('onClear')
    }
  }
}
</script>