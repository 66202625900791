import { Commit, Dispatch } from 'vuex';
import axios from '@/plugins/axios';
import { IRedCredentials } from '@/types/interfaces';
import RedCredentials from '@/models/RedCredentials';

export default {
  namespaced: true,
  state: {
    credentials: null
  },
  actions: {
    async oauth ({commit}: { commit: Commit }) : Promise<any> {
      try {
        const response = await axios.post(`/consumers/red/oauthUrl`)
        // commit('SET_CREDENTIALS', response.data)
        return Promise.resolve(response.data)
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async uninstall ({commit}: { commit: Commit }) : Promise<any> {
      try {
        const response = await axios.post(`/consumers/red/uninstall`)
        commit('shopify/SET_CONSUMER', null, { root: true })
        return Promise.resolve(response.data)
      } catch (err) {
        return Promise.reject(err)
      }
    },

    
    async fetchCredentials ({commit}: { commit: Commit }) : Promise<any> {
      try {
        const response = await axios.get(`/consumers/red/credentials`)
        commit('SET_CREDENTIALS', response.data)
        return Promise.resolve(response.data)
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async updateCredentials ({commit, dispatch}: { commit: Commit, dispatch: Dispatch }, { credentials }: { credentials: IRedCredentials}) : Promise<any> {
      try {
        const response = await axios.put(`/consumers/red/credentials`, {
          appKey: credentials.appKey,
          appSecret: credentials.appSecret
        })
        await dispatch('shopify/fetchAccount', null, { root: true})
        return Promise.resolve(response.data)
        return Promise.resolve(true)
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async disconnect ({commit, dispatch}: any ): Promise<any> {
      try {
        const response = await axios.delete(`/consumers/red/credentials`)
        await dispatch('shopify/fetchAccount', null, { root: true})
        commit('SET_CREDENTIALS', null)
        return Promise.resolve(null)
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async login ({commit}: { commit: Commit }) : Promise<any> {
      try {
        const response = await axios.get(`/consumers/red/oauth/login`)
        return Promise.resolve(response.data)
      } catch (err) {
        return Promise.reject(err)
      }
    },
    // async deleteAccount({commit}: { commit: Commit }) : Promise<any> {
    //   try {
    //     const response = await axios.delete(`/consumers/red/account`)
    //     commit('shopify/SET_ACCOUNT', response.data, { root: true });
    //     return Promise.resolve(response.data)
    //   } catch (err) {
    //     return Promise.reject(err)
    //   }
    // }
  },
  mutations: {
    SET_CREDENTIALS (state: any, credentials: RedCredentials) {
      state.credentials = credentials || new RedCredentials()
    }
  },
  getters: {
    getCredentials(state: any) {
      return state.credentials
    }
  }
}