import { Commit, Dispatch } from 'vuex';
import axios from '@/plugins/axios';
import User from '@/models/User'
import { IAuthUser } from '@/types/interfaces';

export default {
  namespaced: true,
  state: {
    currentUser : null,
    token: null,
    users: []
  },
  actions: {
    async fetchProfile ({commit}: { commit: Commit }): Promise<User> {
      try {
        const response = await axios.get(`/users/profile`);
        commit('SET_USER', new User(response.data) )
        return Promise.resolve(new User(response.data))
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async register ({commit, dispatch}: { commit: Commit, dispatch: Dispatch }, { email, password }: IAuthUser): Promise<User> {
      try {
        const response = await axios.post(`/users/register`, { email, password })

        let user = new User(response.data.user)
        let token = response.data.accessToken

        commit('SET_USER', user)
        dispatch('persistToken', token)

        return Promise.resolve(user)
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async login ({commit, dispatch}: { commit: Commit, dispatch: Dispatch }, { email, password }: IAuthUser): Promise<User> {
      try {
        const response = await axios.post(`/users/login`, { email, password })

        let user = new User(response.data.user)
        let token = response.data.accessToken

        commit('SET_USER', user)
        dispatch('persistToken', token)

        return Promise.resolve(user)
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async persistToken ({commit}: { commit: Commit }, token: string ) {
      localStorage.setItem(process.env.VUE_APP_COOKIE_NAME, token)
      axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem(process.env.VUE_APP_COOKIE_NAME)}`
      commit('SET_TOKEN', token)
    },
    async logout ({commit}: { commit: Commit }): Promise<void> {
      localStorage.removeItem(process.env.VUE_APP_COOKIE_NAME)
      commit('SET_USER', null)
      commit('shopify/SET_ACCOUNT', null, { root: true })
      return Promise.resolve()
    }
  },
  mutations: {
    SET_USER (state: any, user: User) {
      state.currentUser = user
    },
    SET_TOKEN (state: any, token: string) {
      state.token = token
    }
  },
  getters: {
    getUsers(state: any) {
      return state.users
    },
    getProfile(state: any) {
      return state.currentUser
    },
    isAuthentified(state: any) {
      return state.currentUser ? true : false
    }
  }
}