var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:("_uid_" + _vm.lastUpdate)},[_c('div',{staticClass:"hidden"},[_vm._t("default")],2),_c('table',{staticClass:"table table-auto w-full",class:{
      'border-b border-gray-200': !_vm.noBottomBorder,
      'no-header': !_vm.showHeader,
      'border border-gray-200 rounded': !_vm.noBorder
    },staticStyle:{"border-collapse":"inherit","border-spacing":"inherit"}},[_c('colgroup',[(_vm.checkbox)?_c('col',{staticClass:"w-12"}):_vm._e(),_vm._l((_vm.superColumns),function(column,index){return _c('col',{key:index,attrs:{"width":column.width,"min-width":column.minWidth}})})],2),(_vm.showHeader)?_c('thead',[_c('tr',{},[(_vm.checkbox)?_c('th',{staticClass:"w-12"},[_c('ui',{attrs:{"element":"checkbox","value":_vm.isAllSelected},on:{"input":_vm.clickAllCheckbox}})],1):_vm._e(),_vm._l((_vm.superColumns),function(column,index){return _c('th',{key:index,staticClass:"text-sm pr-2 py-2",class:{'first:pl-6 first:rounded-tl last:rounded-tr last:pr-6': !_vm.noXPadding, 'first:pl-0': _vm.noXPadding}},[_c('div',{staticClass:"flex items-center font-normal min-h-12",class:[
              column.staticClass, 
              column.dynamicClass,
              {'justify-center': column.align == 'center', 'justify-end': column.align == 'right'}
            ]},[(column.label)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(column.help),expression:"column.help"}],staticClass:"text-base leading-tight font-medium text-gray-500 flex items-center",class:[column.staticClass, column.dynamicClass, {'border-b cursor-pointer': column.help, 'cursor-pointer': column.sortable}],on:{"click":function($event){column.sortable && _vm.sort(column.field)}}},[(column.sortable && _vm.sortOptions)?_c('span',{staticClass:"flex flex-1 items-center mr-1"},[(column.field != _vm.sortOptions.sortBy)?_c('w-icon',{attrs:{"size":"2","file":"sort"}}):(column.field == _vm.sortOptions.sortBy && 'ASC' == _vm.sortOptions.sortDirection)?_c('w-icon',{attrs:{"size":"2","file":"sort-up"}}):(column.field == _vm.sortOptions.sortBy && 'DESC' == _vm.sortOptions.sortDirection)?_c('w-icon',{attrs:{"size":"2","file":"sort-down"}}):_vm._e()],1):_vm._e(),_c('span',{class:{'text-gray-500': column.sortable && column.field == _vm.sortOptions.sortBy}},[_vm._v(_vm._s(column.label))])]):_vm._e()])])})],2)]):_vm._e(),_c('tbody',{staticClass:"bg-white rounded-b"},[_vm._l((_vm.data),function(item,index){return _c('tr',{key:("tr_" + index),staticClass:"tr",class:[item.rowClass, {'cursor-pointer hover:bg-gray-50': _vm.selectable, 'bg-indigo-100 hover:bg-indigo-200': _vm.checkbox && _vm.selectedItems.includes(item[_vm.selectBy])}],on:{"click":function($event){return _vm.clickItem(item, index)}}},[(_vm.checkbox)?_c('td',{staticClass:"w-12 text-center"},[_c('div',{staticClass:"flex items-center justify-center"},[_c('ui',{attrs:{"element":"checkbox","value":_vm.selectedItems.includes(item[_vm.selectBy])}})],1)]):_vm._e(),_vm._l((_vm.superColumns.filter(function (c) { return c.type != 'expand'; })),function(column,index_c){return _c('table-cell',{key:index_c,class:[
            column.staticClass, 
            column.dynamicClass,
            {
              'first:pl-6 pr-2 last:pr-6': !_vm.noXPadding,
              'first:pl-0 pr-2 last:pr-0': _vm.noXPadding,
              'align-top': _vm.valign == 'top',
              'text-center': column.align == 'center',
              'text-right': column.align == 'right'
            }
          ],attrs:{"id":(index + "_" + index_c),"column":column,"item":item,"rowIndex":index}})})],2)}),(_vm.$slots.total)?_c('tr',{staticClass:"bg-gray-50 font-semibold",attrs:{"slot":"header"},slot:"header"},[_vm._t("total")],2):_vm._e()],2)]),(_vm.data && _vm._isEmpty(_vm.data))?_c('div',{staticClass:"flex py-3 justify-center text-gray-400"},[_vm._v(" No Data ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }