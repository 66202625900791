import Vue from 'vue';

Vue.filter('decamelize', function(value) {
  return value.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1")
})
Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  value = value.replace(/[^\w\s]/gi, " ").replace(/_/g, " ");
  return value.charAt(0).toUpperCase() + value.slice(1)
})