import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import { RootState } from './types';
import shopify from "./shopify/shopify";
import red from "./red/red";
import users from "./users";
import ShopifyProductsModule from './shopify/shopify-products';
import RedProductsModule from './red/red-products';
import ContextModule from './context';
import ShopifySettings from './shopify/shopify-settings';
import RedPreferences from './red/red-preferences';
import RedNotifications from './red/red-notifications';

Vue.use(Vuex)

const store: StoreOptions<RootState> = {
  state: {
      version: '1.0.0'
  },
  strict: false,
  modules: {
    shopify,
    red,
    users,
    shopifyProducts: ShopifyProductsModule,
    redProducts: RedProductsModule,
    context: ContextModule,
    shopifySettings: ShopifySettings,
    redPreferences: RedPreferences,
    redNotifications: RedNotifications
  }
};

export default new Vuex.Store<RootState>(store);