export default class RedCredentials {

  public id: string = '';
  public appKey: string = '';
  public appSecret: string = '';

  constructor(data?: { appKey: string, appSecret: string }) {
    if(data) {
      this.appKey = data.appKey;
      this.appSecret = data.appSecret;
    }
  }
}