import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import RedProduct from '@/models/RedProduct';
import axios from '@/plugins/axios';
import { IPagination } from '@/types/interfaces';

enum ProviderType {
  shopify = 'shopify'
}

import { config } from 'vuex-module-decorators'
config.rawError = true

@Module({namespaced: true})
export default class RedProductsModule extends VuexModule {
  count:number = 0
  items:RedProduct[] = []
  productsToMatch:number = 0
  matchedProducts:number = 0
  totalProducts:number = 0

  @Action({ commit: 'setItems' })
  async fetchAll({ pagination: { page, pageSize }, isMatched}: { pagination: IPagination, isMatched: Boolean}): Promise<{items: RedProduct[], count: number}> {

    let skip = (page - 1) * pageSize
    try {
      const response = await axios.get(`/consumers/red/products/list?skip=${skip}&limit=${pageSize}&sort=createdAt&isMatched=${isMatched}`)
      return Promise.resolve(response.data)
    } catch (err) {
      return Promise.reject(err)
    } 
  }

  @Action({ commit: 'setItems' })
  async searchAll({pagination, q, isMatched}: { pagination: IPagination, q: string, isMatched: Boolean}): Promise<{items: RedProduct[], count: number}> {
    let skip = (pagination.page - 1) * pagination.pageSize
    try {
      const response = await axios.get(`/consumers/red/products/search?skip=${skip}&limit=${pagination.pageSize}&sort=createdAt&isMatched=${isMatched}&q=${q}`)
      return Promise.resolve(response.data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  @Action({ commit: 'setMatch' })
  async match({ consumerComponentId, providerComponentId, providerType }: { consumerComponentId: string, providerComponentId: string, providerType: ProviderType }): Promise<RedProduct> {
    try {
      const response = await axios.post(`/consumers/red/products/${consumerComponentId}/match`, {
        componentId: providerComponentId
      })
      return Promise.resolve(response.data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  @Action({ commit: 'setUnmatch' })
  async unmatch({ consumerComponentId, providerType }: { consumerComponentId: string, providerType: ProviderType }): Promise<RedProduct> {
    try {
      const response = await axios.delete(`/consumers/red/products/${consumerComponentId}/match`, {
        params: { providerType: providerType }
      })

      return Promise.resolve(response.data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  @Action({ commit: 'setCount' })
  async fetchCount(isMatched: Boolean) {
    try {
      const response = await axios.get(`/consumers/red/products/count?isMatched=${isMatched}`)
      return Promise.resolve({ isMatched: isMatched, count: response.data['count'] })
    } catch (err) {
      return Promise.reject(err)
    }
  }
  @Action({ commit: 'setCounts' })
  async fetchCounts() {
    try {
      const total = await axios.get(`/consumers/red/products/count`)
      const matched = await axios.get(`/consumers/red/products/count?isMatched=true`)

      return Promise.resolve({ matched: matched.data['count'], unmatched: (total.data['count'] - matched.data['count']), total: total.data['count'] })
    } catch (err) {
      return Promise.reject(err)
    }
  }

  @Action
  async synchronizeProducts() {
    try {
      const response = await axios.post(`/consumers/red/synchronization/products`)
      return Promise.resolve(response)
    } catch (err) {
      return Promise.reject(err)
    }
  }


  @Mutation
  setItems(payload: {items: RedProduct[], count: number}) {
    this.items = payload.items
    this.count = payload.count
  }

  @Mutation
  setCount({isMatched, count}: {isMatched: Boolean, count: number}) {
    if(isMatched === true) {
      this.matchedProducts = count
    } else if (isMatched === false) {
      this.productsToMatch = count
    } else {
      this.totalProducts = count
    }
  }

  @Mutation
  setCounts({matched, unmatched, total}: {matched: number, unmatched: number, total: number}) {
    this.matchedProducts = matched
    this.productsToMatch = unmatched
    this.totalProducts = total
  }

  @Mutation
  setMatch(payload: RedProduct) {
    Object.assign(this.items[this.items.findIndex(el => el.id === payload.id)], payload)
    this.productsToMatch -= 1
    this.matchedProducts += 1
  }

  @Mutation
  setUnmatch(payload: RedProduct) {
    Object.assign(this.items[this.items.findIndex(el => el.id === payload.id)], payload)
    this.productsToMatch += 1
    this.matchedProducts -= 1
  }

  get getProducts(): {items: RedProduct[], count: number} {
    return {
      items: this.items, count: this.count
    }
  }

  get getCount(): number {
    return this.productsToMatch
  }
}