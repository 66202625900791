import { Module, VuexModule, Mutation, Action, MutationAction } from 'vuex-module-decorators'
import axios from '@/plugins/axios';
import RedNotification from '@/models/RedNotification';

@Module({namespaced: true})
export default class RedNotificationsModule extends VuexModule {
  notifications: RedNotification[] = []
  categories: object[] = []

  @MutationAction({ mutate: ['categories'] })
  async fetchCategories(): Promise<{categories: object[]}> {
    try {
      const response = await axios.get(`/consumers/red/notification-categories`)
      return Promise.resolve({ categories: response.data })
    } catch (err) {
      return Promise.reject(err)
    }
  }

  @MutationAction({ mutate: ['notifications'] })
  async fetchNotifications(): Promise<{notifications: RedNotification[]}> {
    try {
      const response = await axios.get(`/consumers/red/notifications`)
      return Promise.resolve({ notifications: response.data })
    } catch (err) {
      return Promise.reject(err)
    }
  }

  //@MutationAction({ mutate: ['notifications'] })
  @Action({ commit: 'addReceiver' })
  async updateNotifications(notification: RedNotification): Promise<RedNotification> {
    try {
      const response = await axios.post(`/consumers/red/notifications`, notification)
      return Promise.resolve(notification)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  @Action({ commit: 'removeReceiver' })
  async deleteNotification(notification: RedNotification): Promise<RedNotification> {
    try {
      const response = await axios.delete(`/consumers/red/notifications`, { 
        data: notification
      })
      return Promise.resolve(notification)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  @Mutation
  addReceiver(payload: RedNotification) {
    if(this.notifications.find(n => n.receiver == payload.receiver)) {
      Object.assign(this.notifications[this.notifications.findIndex(el => el.receiver === payload.receiver)], payload)
    } else {
      this.notifications.push(payload)
    }
  }

  @Mutation
  removeReceiver(payload: RedNotification) {
    this.notifications = this.notifications.filter(n => n.receiver != payload.receiver)
  }

  get getCategories(): object[] {
    return this.categories
  }
}