
























import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class ProductsPage extends Vue {
  @Prop({ type: Number, default: 1 }) currentPage!: number
  @Prop({ type: Number, default: 10 }) pageSize!: number
  @Prop(Number) readonly total: number | undefined
  @Prop({ default: true }) private updateQuery: Boolean

  get totalPages(): number {
    if(this.total) {
      return Math.ceil(this.total / this.pageSize)
    } else {
      return 0
    }
  }

  clickPage(value: number) {
    if(this.updateQuery && value) {
      let query = Object.assign({}, this.$route.query, { page: value.toString() })
      this.$router.push({query})
    }
    this.$emit('onChangePage', value)
  }

  isVisible(page: number) {
    let minimum = (this.currentPage - 3)
    let maximum = this.currentPage + 3

    if(minimum < 0) {
      maximum += -minimum
    }
    if(maximum > this.totalPages) {
      minimum -= (maximum - this.totalPages)
    }

    let ok = page > minimum && page < maximum
    ok = ok || (page == 1 || page == this.totalPages)
    
    return ok
  }
}
