import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import '@/assets/css/style.css'
import i18n from './i18n'
require('./plugins/ui-components');
import axios from './plugins/axios';
require('./plugins/errorhandler');
require('./plugins/click-outside');
require('./plugins/errorhandler');
require('./plugins/filters');
import Utils from './plugins/utils'

import GlobalConfirm from './plugins/confirm/confirm';
Vue.use(GlobalConfirm)

import VTooltip from 'v-tooltip'
Vue.use(VTooltip)

import Notifications from 'vue-notification';
Vue.use(Notifications)

import PortalVue from 'portal-vue'
Vue.use(PortalVue)

// import UUID from "vue-uuid";
// Vue.use(UUID);

import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { extend } from 'vee-validate';
import { localize } from 'vee-validate';
import en from 'vee-validate/dist/locale/en.json'
import cn from 'vee-validate/dist/locale/zh_CN.json'
import * as Rules from 'vee-validate/dist/rules'

for (var rule in Rules) {
  extend(rule, (Rules as any)[rule])
}

localize({ en, cn });

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.use(require('vue-moment'));

Vue.config.productionTip = false

Vue.prototype.$http = axios;

function checkAuth() {

  store.dispatch('context/setAppLoading', true)

  return new Promise(async (resolve, reject) => {
    if(true) {//Utils.inIframe()) {
      // Here comes the code for Shopify
    } else {
      // We're getting the current token from localStorage
      const token = window.localStorage.getItem(process.env.VUE_APP_COOKIE_NAME) || process.env.VUE_APP_SHOPIFY_TOKEN
      if (token && (typeof token !== 'undefined') && token != 'undefined') {
        try {
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
          await store.dispatch('users/fetchProfile')
          try {
            await store.dispatch('shopify/fetchAccount')
            await store.dispatch('redProducts/fetchCounts')
          } catch (err) {
            console.log("Error", err)
          }

          resolve(true)

        } catch (err) {
          console.log("User profile - Error", err)
          await store.dispatch('users/logout')

          reject(err)
        }
      }
    }
    resolve(true)
  })
}

function createApp() {
  new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
  }).$mount('#app')
}

checkAuth().then(async () => {
  createApp();
  if(true) {//Utils.inIframe() && window && window.location && window.location.search) {
    try {
      let params = window.location.search
        .slice(1)
        .split('&')
        .map(p => p.split('='))
        .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});
      
      await store.dispatch('shopify/setSignature', params)
      await store.dispatch('shopify/login')

    } catch (err) {
      console.log("Shopify login - Error", err)
    }
  }

}).catch(err => {
  createApp();
  console.log("err", err)
  
  switch(err.statusCode) {
    case 401:
      router.push('/errors/401'); 
      break;
    default: 
      router.push('/errors/network');
  }

}).finally(() => {
  store.dispatch('context/setAppLoading', false)
})