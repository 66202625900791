import { Module, VuexModule, Mutation, Action, MutationAction } from 'vuex-module-decorators'
import axios from '@/plugins/axios';
import { IRedLogisticCode, IRedPreferences } from '@/types/interfaces';

@Module({namespaced: true})
export default class RedPreferencesModule extends VuexModule {
  preferences: object = {}
  codes: object = {}

  @MutationAction({ mutate: ['preferences'] })
  async fetchPreferences(): Promise<{preferences: IRedPreferences}> {
    try {
      const response = await axios.get(`/consumers/red/preferences`)
      return Promise.resolve({ preferences: response.data })
    } catch (err) {
      return Promise.reject(err)
    }
  }

  @MutationAction({ mutate: ['preferences'] })
  async updatePreferences(settings: IRedPreferences): Promise<{preferences: IRedPreferences}> {
    try {
      const response = await axios.put(`/consumers/red/preferences`, settings)
      return Promise.resolve({ preferences: response.data })
    } catch (err) {
      return Promise.reject(err)
    }
  }

  @MutationAction({ mutate: ['codes'] })
  async fetchLogisticCodes(): Promise<{codes: IRedLogisticCode[]}> {
    try {
      const response = await axios.get(`/consumers/red/logistic-codes`)
      return Promise.resolve({ codes: response.data })
    } catch (err) {
      return Promise.reject(err)
    }
  }
  
}