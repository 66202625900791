










import { Component, Prop, Vue, Ref } from "vue-property-decorator";

@Component
export default class SelectComponent extends Vue {
  @Prop() public value?: string;

  changeValue(ev: any) {
    this.$emit('input', ev.target.value)
  }
}
