<template>
  <div class="flex items-center gap-4">
    <!-- <input type="checkbox" class="hidden" :value="value" @change="$emit('change', !value)" /> -->
    <div 
      @click="toggle"
      class="w-switch w-18 flex border rounded-full relative items-center"
      :class="[
        value ? 'justify-end bg-pacific-400 border-pacific-400' : 'justify-start bg-gray-100',
        { 'cursor-pointer': !disabled },
        { 'opacity-50': disabled }
      ]">
      <div v-if="value" class="text-white flex-1 text-xs text-center select-none">ON</div>
      <div class="h-8 w-8 shadow-md rounded-full bg-white"></div>
      <div v-if="!value" class="text-gray-500 flex-1 text-xs text-center select-none">OFF</div>
    </div>
    <div class="flex-1"><slot>{{ label }}</slot></div>
  </div>
</template>
<script>
export default {
  name: 'w-switch',
  props: {
    value: Boolean,
    label: String,
    disabled: Boolean
  },
  methods: {
    toggle() {
      if(!this.disabled) {
        let newValue = !this.value
        this.$emit('input', newValue)
        this.$emit('change', newValue)
      }
    }
  }
}
</script>
<style>
.w-switch {
  transition: all .3s ease-out;
}
</style>