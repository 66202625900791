<template>
  <component :is="file" />
</template>
<script>
// import ChevronDown from '@/components/ui/icons/chevron-down.vue'
// import Spinner from '@/components/ui/icons/spinner.vue'
// import CloseCircle from '@/components/ui/icons/close-circle.vue'

export default {
  name: 'w-icon',
  // components: { 
  //   ChevronDown, Spinner, CloseCircle
  // },
  props: {
    file: {
      type: String
    }
  }
}
</script>